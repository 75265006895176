import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import styles from './input.module.scss';

function Input(props) {
   const {
      id,
      name,
      label,
      register,
      value,
      placeholder,
      type,
      size,
      mask,
      required,
      disabled,
      error,
      validation,
      autocomplete,
      maxValue,
      pattern,
      iconRight,
      className,
      classLabel,
      onChange,
      onFocus,
      textPrepend,
      clearErrors,
      onKeyDown,
      style,
   } = props;
   const inputClass = classNames({
      [styles.wrapper]: true,
      [styles.fullWidth]: size === 'full',
      [styles.halfWidth]: size === 'half',
      [styles.smallWidth]: size === 'small',
      [styles['wrapper--error']]: !!error,
      [className]: !!className,
   });
   const labelClass = classNames({
      [styles.label]: true,
      [classLabel]: !!classLabel,
      [styles['label--required']]: !!required,
   });

   const getInput = () => {
      if (error && disabled) clearErrors(name);
      const onChangeAfter = (e) => {
         if (onChange) {
            onChange(e);
         }
      };

      return (
         <InputMask
            ref={
               register &&
               register({
                  required: required ? 'Este campo es obligatorio' : false,
                  ...validation,
                  pattern,
               })
            }
            value={value}
            mask={mask}
            maskPlaceholder=""
            onChange={onChangeAfter}
            disabled={disabled}
            onFocus={onFocus}
            name={name}
            type={type}
            placeholder={placeholder}
            autoComplete={autocomplete}
            max={maxValue}
            aria-label={label}
            id={id}
            onKeyDown={onKeyDown}
            style={style}
         />
      );
   };
   return (
      <fieldset className={inputClass}>
         {label && (
            <label htmlFor={name} className={labelClass}>
               {label}
            </label>
         )}
         {textPrepend ? (
            <div className={styles.inputWithPrepend}>
               <div className={styles.prepend}>
                  <span className={styles.inputGroupText}>{textPrepend}</span>
               </div>
               {getInput()}
            </div>
         ) : (
            getInput()
         )}

         {iconRight && <div className={styles.iconRight}>{iconRight}</div>}
         {error && (
            <span htmlFor={name} className={styles.errorMessage}>
               {error}
            </span>
         )}
      </fieldset>
   );
}
Input.propTypes = {
   name: PropTypes.string.isRequired,
   label: PropTypes.string,
   placeholder: PropTypes.string,
   type: PropTypes.string,
   size: PropTypes.oneOf(['full', 'half', 'small']),
   required: PropTypes.bool,
   maxValue: PropTypes.string,
   mask: PropTypes.string,
   iconRight: PropTypes.bool,
   disabled: PropTypes.bool,
   error: PropTypes.string,
   pattern: PropTypes.shape({
      value: PropTypes.instanceOf(RegExp),
      message: PropTypes.string,
   }),
   validation: PropTypes.shape({
      minLength: PropTypes.shape({
         value: PropTypes.number,
         message: PropTypes.string,
      }),
      maxLength: PropTypes.shape({
         value: PropTypes.number,
         message: PropTypes.string,
      }),
   }),
   textPrepend: PropTypes.string,
};
Input.defaultProps = {
   type: 'text',
   placeholder: null,
   label: null,
   size: 'full',
   maxValue: null,
   mask: null,
   pattern: null,
   required: false,
   iconRight: false,
   disabled: false,
   error: null,
   validation: {},
   textPrepend: null,
};
export default Input;
